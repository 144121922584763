<script setup>
import {ref} from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    items: [
      {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
      {label: 'Analytics', icon: 'pi pi-fw pi-chart-line', to: '/analytics'},
      {label: 'Publishers', icon: 'pi pi-fw pi-sitemap', to: '/publishers'},
      {label: 'Advertisers', icon: 'pi pi-fw pi-money-bill', to: '/advertisers',},
    ]
  }
]);
const authStore = useAuthStore();
if (authStore.isAdminUser) {
  model.value = [ {
    items: [
      ...model.value[0].items,
      {label: 'Secret stat', icon: 'pi pi-fw pi-money-bill', to: '/page-for-gray-cardinal-muha'},
    ]
  }]
}
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
